// Bootstrap colors / variable overrides

// @import '~@fontsource/lato/100.css';
// @import '~@fontsource/lato/300.css';
// @import '~@fontsource/lato/400.css';
// @import '~@fontsource/lato/700.css';
// @import '~@fontsource/roboto/100.css';
// @import '~@fontsource/roboto/300.css';
// @import '~@fontsource/roboto/400.css';
// @import '~@fontsource/roboto/500.css';
// @import '~@fontsource/roboto/700.css';

@import './styles/custom.scss';
@import 'node_modules/bootstrap/scss/bootstrap';

//@import '~@swimlane/ngx-datatable/index.css';
//@import '~@swimlane/ngx-datatable/themes/bootstrap';
//@import '~@swimlane/ngx-datatable/themes/material';
//@import '~@swimlane/ngx-datatable/themes/dark';
//@import '~@swimlane/ngx-datatable/assets/icons.css';

//@import '~@swimlane/ngx-datatable/themes/bootstrap';
//@import '~@swimlane/ngx-datatable/assets/icons.css';
//@import '~@ng-select/ng-select/themes/default.theme.css';

:root {
  --faded12: rgb(209 230 243 / 12%);
  --faded32: rgb(209 230 243 / 32%);
  --faded50: rgb(209 230 243 / 50%);
  --white50: rgb(255 255 255 / 50%);
  --white: #fff;
  --primary: #d11f7a;
  --secondary: #2b2940;
  --dark: #1c1a32;
  --darkerGrey: #3e4055;
  --background: #2b2940;
  --autoFill: #2b2940b4;
  --trackThumb: #606579;
  --text-large: 0.875rem;
  --text-medium: 0.75rem;
  --text-label: 0.6875rem;
  --text-small: 0.625rem;
  --sm: 500px;
  --md: 1000px;
  --bs-btn-active-border-color: #d11f7a;
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #1c1a32;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.25rem;
  --bs-accordion-inner-border-radius: calc(0.25rem - 1px);
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 0;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bc1c6e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 2px;
  --bs-accordion-active-color: #bc1c6e;
  --bs-accordion-active-bg: var(--secondary);
}

body,
html {
  font-size: 16px;
  background-color: $secondary;
  overflow: hidden;
  font-family: Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
}

.logo {
  left: 0px;
  top: 0px;
  width: 100px;
  min-width: 100px;
  height: 60px;
  position: relative;
  background-size: 100%;
  background-image: url(./assets/inspire-white-magenta.svg);
  background-repeat: no-repeat;
  background-position-y: center;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}

.app-version {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: smaller;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}

.environment {
  font-size: x-large;
  color: rgba(255, 255, 255, 0.1);
}

.center {
  display: flex;
  justify-content: center;
}

.text-small {
  // 10px
  font-size: var(--text-small);
}

.text-medium {
  // 12px
  font-size: var(--text-medium);
}

.text-large {
  // 14px
  font-size: var(--text-large);
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-0 {
  margin: 0;
}

div#main-content {
  padding: 0;
}

/* Additional color helpers */

.faded12 {
  color: var(--faded12);
}
.bg-faded12 {
  background-color: var(--faded12);
}
.faded32 {
  color: var(--faded32);
}
.bg-faded32 {
  background-color: var(--faded32);
}
.faded50 {
  color: var(--faded50);
}
.bg-faded50 {
  background-color: var(--faded50);
}
.white50 {
  color: var(--white50);
}
.bg-white50 {
  background-color: var(--white50);
}
.bg-darkerGrey {
  background-color: var(--darkerGrey);
}

/* Base styles*/
.headerContainer h1 {
  margin: 0;
}

nav.navbar.navbar-dark.bg-dark {
  height: 60px;
}

.form-group.icon input.ng-invalid.ng-touched:not(:focus),
.form-group.icon textarea.ng-invalid.ng-touched:not(:focus),
.form-group.icon .ng-select.ng-invalid.ng-touched:not(.ng-select-opened) .ng-select-container {
  background-image: url(/assets/cross.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.form-group.icon input.ng-valid.ng-touched:not(:focus),
.form-group.icon textarea.ng-valid.ng-touched:not(:focus),
.form-group.icon .ng-select.ng-valid.ng-touched:not(.ng-select-opened) .ng-select-container {
  background-image: url(/assets/tick.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.form-group textarea.ng-touched:not(:focus) {
  background-position-y: 13px !important;
}

.form-group .ng-select .ng-select-container {
  background-position-x: calc(100% - 36px) !important;
}

.login-screen .form-group input {
  background-image: none !important;
}

div#userPanel {
  position: relative;
  z-index: 999;
}

// REVIEW COMMENTS BUTTONS AND CUSTOM BUTTON CLASSES!!!!!
button,
input[type='submit'],
input[type='reset'],
input {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

/* Custom form controls */

.form-check.custom-control {
  display: inline-flex;
}

.form-check {
  margin-bottom: 0.5rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $primary;
  background-color: $primary;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: transparent !important;
  box-shadow: 0px 0px 0 1px $white;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #f1f3f3;
  background-color: $secondary;
  border-color: #8f9ea8;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: $dark;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: $white;
  opacity: 0.32;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $primary;
}
.form-control:disabled {
  background-color: $secondary;
  opacity: 0.375;
}

.fieldLabel:disabled {
  opacity: 0.375;
}

.formArray.disabled .dynamicDisable .custom-control-input ~ .custom-control-label::before {
  color: $primary !important;
  border-color: $primary !important;
  background-color: $primary !important;
  color: $white !important;
}

.formArray.disabled .dynamicDisable > .custom-checkbox .custom-control-input ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.disabledQuestion .formArray.disabled .dynamicDisable .custom-control-input ~ .custom-control-label::before {
  background-color: #1c1a32 !important;
  border: 1px solid #4f5271 !important;
}

.disabledQuestion
  .formArray.disabled
  .dynamicDisable
  > .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #1c1a32 !important;
}

.disabledQuestion
  .formArray.disabled
  .dynamicDisable
  > .custom-checkbox
  .custom-control-input
  ~ .custom-control-label::after {
  background-image: none !important;
}

/* Bootstrap popover */

button.btn.btn-link.popOver {
  background-image: url(/assets/info-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  top: 3px;
  height: 16px;
  background-position-x: center;
  /* background-color: aliceblue; */
  position: absolute;
  right: 0;
  padding: 0;
  border-radius: 50%;
  z-index: 999;
}

button.btn.btn-link.popOver:focus {
  box-shadow: 0 0 0 1px $white;
  border-radius: 50%;
}

.ddqPopOver.popover .popover-body ul {
  list-style-type: disc;
  margin-left: 0.75rem;
}

.ddqPopOver.popover .popover-body p {
  font-size: $font-size-sm;
}

app-dc-popover.helpText button.btn.btn-link.popOver {
  position: relative;
  left: 6px;
  top: -1px;
}

/* Date picker */

button.btn.btn-link.calendar {
  border-radius: 0.25rem 0 0 0.25rem;
  background-color: $secondary;
  // border: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  padding-top: 7px;
  border-right-color: transparent;
  border-right: 0;
}

.dark button.btn.btn-link.calendar {
  background-color: $dark;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $secondary;
  opacity: 1;
}

.dark .form-control:disabled,
.dark .form-control[readonly] {
  background-color: $dark;
}

.btn.btn-link.calendar:focus,
.btn.btn-link.calendar .focus {
  outline: 0;
}

.dark .form-control:disabled,
.dark .form-control[readonly] {
  background-color: $dark;
}

.ngb-dp-header {
  background-color: $secondary !important;
}

// .dark .ngb-dp-header {
//   // background-color: $dark !important;
// }

.ngb-dp-header .btn-link {
  font-weight: 400;
  color: $white;
}

.ngb-dp-header select.custom-select {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
  min-width: 70px;
}

// .dark .ngb-dp-header select.custom-select {
//   // background-color: $dark;
//   // border-color: $dark;
// }

.ngb-dp-header select.custom-select:focus {
  box-shadow: none;
  border-color: $white;
}

.ngb-dp-navigation-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 4rem;
  flex: 1 1 4rem;
}

.btn-light.bg-primary.text-white {
  background-color: $primary !important;
}

.ngb-dp-month {
  background-color: $secondary;
}

// .dark .ngb-dp-month {
//   // background-color: $dark;
// }

.ngb-dp-week.ngb-dp-weekdays {
  background-color: $secondary;
  color: $white;
}

// .dark .ngb-dp-week.ngb-dp-weekdays {
//   // background-color: $dark;
// }

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
  font-size: 0.75rem;
}

.ngb-dp-weekday.small {
  color: $white;
  font-style: normal;
  text-transform: uppercase;
}

.btn-light.text-muted {
  color: #d1e6f3 !important;
  opacity: 0.32;
}

.btn-light {
  color: $white;
}

.ngb-dp-day.ngb-dp-today .btn-light {
  color: #e06764;
}

.ngb-dp-day .btn-light:hover {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

input#datePickerComponent::placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

ngb-datepicker.dropdown-menu.show {
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 4px rgb(28 26 50 / 32%);
}

/* Date-picker Range */

.dark.input-group .form-control {
  background-color: $dark;
}

.input-group .ngb-dp-month-name {
  background-color: $secondary;
}

// .dark.input-group .ngb-dp-month-name {
//   // background-color: $dark;
// }

/* NG-select */
.ng-dropdown-panel {
  background-color: $secondary;
  border: 1px solid $secondary !important;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  margin-top: 9px !important;
  border-radius: 4px !important;
}

.ng-select .ng-select-container {
  color: $white;
  border: 1px solid $secondary;
  background-color: $secondary;
  justify-content: space-between;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: $secondary;
  border-color: $white;
}

.ng-select.ng-select-opened.readonly-dropdown > .ng-select-container {
  background: $secondary;
  border-color: transparent;
  cursor: default !important;
}

//fixes dropdown text color of single select
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 0.75rem;
  color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: $secondary;
  font-weight: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: $white;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: $secondary;
  color: $white;
  font-size: 0.75rem;
}

// .ng-select.dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   // background-color: #1c1a32;
// }

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: $white;
  background-color: $secondary;
}

.ng-select.withCheckbox .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled) {
  padding-left: 30px;
  position: relative;
}

.ng-select.withCheckbox .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled):before {
  content: '';
  width: 16px;
  height: 16px;
  background-color: $secondary;
  position: absolute;
  left: 8px;
  box-shadow: 0 0 0 1px #4f5271;
  border-radius: 4px;
}

// .ng-select.withCheckbox.dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:not(.ng-option-disabled):before {
//   // background-color: #1c1a32;
// }

.ng-select.withCheckbox .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected:not(.ng-optgroup):before {
  content: '';
  width: 16px;
  height: 16px;
  background-color: $primary !important;
  position: absolute;
  left: 8px;
  box-shadow: 0 0 0 1px $primary !important;
  border-radius: 4px;
}
.ng-select.withCheckbox .ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected:not(.ng-optgroup):after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 8px;
  top: 8px;
  background: 50%/50% 50% no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #34324e;
  color: $white;
}

.ng-value-container {
  max-width: 80%;
  font-size: 0.75rem;
}

.ng-placeholder {
  font-size: 0.75rem !important;
  padding: 4px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  max-width: 100%;
}

//fixes text color on pink bacground multiselect
.ng-select.ng-select-multiple span.ng-value-label {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  font-size: 0.75rem;
  color: white;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: transparent;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #b11b69;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $white;
  box-shadow: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened).readonly-dropdown > .ng-select-container {
  border-color: transparent;
  box-shadow: none;
  cursor: default !important;
}

// .ng-select .ng-clear-wrapper {
//   display: none;
// }

.ng-select.ng-select-multiple .ng-select-container {
  position: relative;
}

.ng-select.dark .ng-select-container {
  background-color: #1c1a32;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #4f5271;
  font-size: 0.75rem;
}

span.ng-value-icon.left {
  display: none !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: $secondary;
  opacity: 0.375;
}

.ng-select.readonly-dropdown:not(ng-select-disabled) > .ng-select-container {
  background-color: $secondary;
  opacity: 0.5 !important;
}

.ng-select.ng-select-disabled.readonly-dropdown > .ng-select-container {
  background-color: $secondary;
  opacity: 0.5 !important;
}

.ng-select .ng-arrow-wrapper {
  width: 30px;
}

.ng-select .ng-arrow-wrapper .ng-select span.ng-arrow-wrapper {
  position: absolute;
  right: 0;
  width: auto;
  padding-right: auto;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $white transparent transparent;
}

span.ng-arrow {
  border: none !important;
  background-image: url(/assets/dropdown-chevron.svg) !important;
  background-repeat: no-repeat;
  height: 0.375rem !important;
  background-position-y: center;
  width: 30px !important;
  top: -2px !important;
  left: 0;
  background-size: contain;
  background-position-x: center;
}

.ng-select-opened:not(.readonly-dropdown) span.ng-arrow {
  transform: rotateZ(180deg);
  left: 1px;
}

.ng-select.readonly-dropdown .ng-arrow-wrapper {
  cursor: default !important;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.ng-select.dark .ng-select-container {
  background-color: #1c1a32;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-top: 3px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

/* Scrollbar styles */
/* Works on Firefox */
* {
  scrollbar-width: 10px;
  scrollbar-color: var(--trackThumb) $dark !important;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: $dark !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--trackThumb) !important;
  border-radius: 20px;
}

/* autofill styles */

input:-webkit-autofill {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-moz-autofill {
  -moz-text-fill-color: $white;
  -moz-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-o-autofill {
  -o-text-fill-color: $white;
  -o-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-khtml-autofill {
  -khtml-text-fill-color: $white;
  -khtml-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}

input:focus:-webkit-autofill {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-moz-autofill {
  -moz-text-fill-color: $white;
  -moz-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-o-autofill {
  -o-text-fill-color: $white;
  -o-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-khtml-autofill {
  -khtml-text-fill-color: $white;
  -khtml-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--autoFill) inset !important;
}

::-webkit-scrollbar-corner {
  background-color: var(--dark);
}

// /* Datatable styles */

.ngx-datatable.bootstrap {
  font-size: 16px;
  //height: 100% !important;
  .datatable-header {
    color: #d1e6f366 !important;
    font-size: 0.625rem;
    opacity: 1;
    .datatable-header-cell {
      text-transform: uppercase;
      &:first-child {
        padding-left: 0.75rem;
      }
    }
  }
  .datatable-body {
    //height: auto !important;
    // max-height: calc(100% + 110px) !important;
    //overflow-y: scroll;
    // max-height: calc(100vh - 250px);
    // min-height: 51px;
    overflow-y: auto;
    .empty-row {
      position: relative;
      padding: 0.75rem;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
    .datatable-body-row {
      vertical-align: middle !important;
      border-top: 1px solid $secondary !important;
      &:hover {
        background-color: rgb(43 41 64 / 40%);
        transition: background-color 300ms linear;
      }
      .datatable-body-cell {
        font-size: 0.75rem;
        &:first-child {
          padding-left: 0.75rem;
        }
      }
      .datatable-body-cell.summary-value {
        overflow: visible;
      }
    }
  }
  .datatable-footer {
    background-color: $dark;
    font-size: 0.75rem;
    opacity: 0.5;
    color: $white;
    .datatable-footer-inner {
      overflow: hidden;
    }
    // pager styling
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0px;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
        // color: #c03678;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
}

.ngx-datatable.bootstrap.contained .datatable-body {
  height: calc(100vh - 280px) !important;
}

.fullHeight .datatable-body {
  height: auto !important;
}
.ngx-datatable.requestList .datatable-header .datatable-header-cell.sortable.sort-active .datatable-header-cell-label {
  color: $white !important;
  opacity: 1;
}

.ngx-datatable.requestList .datatable-icon-sort-unset::before {
  // font-size: 18px;
  // color: $secondary;
  // opacity: 1;
  // line-height: 1;
  // position: relative;
  // top: -1px;
  display: none; //hide up/down sort
}

.ngx-datatable.requestList .datatable-icon-up::before,
.ngx-datatable.requestList .datatable-icon-down::before {
  //color: $primary;
  //font-family: 'Lato' !important;
  // font-size: 0.375rem;
  // margin: 0 0 0 0.3125rem;
  // // padding-bottom: 0.1875rem !important;
  display: block;
  position: relative;
  // top: -1px;
  content: '';
}

.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn {
  cursor: pointer;
  display: inline-block;
  width: 14px;
  line-height: 100%;
  vertical-align: middle;
  background-image: url(/assets/sort-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 4px;
  top: -1px;
  position: relative;
}

.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn.sort-asc {
  transform: rotateZ(180deg);
}
.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn.sort-desc {
  transform: rotateZ(0deg);
}

// .ngx-datatable.requestList datatable-body.datatable-body {
//   //  overflow-y: auto;
// }

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li {
  margin: 0px;
}

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: $secondary;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: transparent;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row:hover {
  background-color: rgba(43, 41, 64, 0.4);
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem;
  padding-left: 0;
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .salesDeskCell,
.ngx-datatable.requestList .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  padding-left: 0.75rem !important;
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .datatable-body-cell.statusCell {
  padding-left: 0;
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .datatable-body-cell.actionCell a {
  color: $primary;
  text-align: center;
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .datatable-body-cell span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

// .ngx-datatable.requestList .datatable-body .datatable-body-row .datatable-body-cell span {
// }

.ngx-datatable.requestList .datatable-body .datatable-body-row .distContactCell span {
  display: block;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  //overflow: visible;
  width: 100%;
}

.ngx-datatable.requestList .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.2rem 0;
  border-bottom: 0px solid $secondary;
}

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li {
  margin: 0px;
}

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: $secondary;
}

.ngx-datatable.requestList .datatable-header .datatable-header-cell.reviewCell {
  display: none !important;
}

.ngx-datatable.requestList .datatable-footer .page-count {
  padding: 0;
}

.ngx-datatable.list-table .datatable-body {
  overflow-x: hidden;
  display: inline;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #2b2940 !important;
  color: #fff;
}
.ngx-datatable span.resize-handle:hover:after,
datatable-header-cell.resizeable span.resize-handle:after {
  background-color: var(--faded12);
  height: 100%;
  content: '';
  width: 3px;
  display: block;
  position: absolute;
  left: -5px;
}

// // datatable-scroller {
// //   width: 100% !important;
// // }

.section-tab-layout {
  border-bottom: $dark 2px solid;
  padding-bottom: 12px;
  .section-tabs {
    display: flex;
    .section-tab {
      border-radius: 16px 16px 0px 0px;
      margin-right: 2px;
      padding: 1em 3em;
      font-size: 14px;
      margin-bottom: -12px;
      border: $dark 3px solid;
      background-color: $dark;
    }
    .section-tab.active {
      background-color: $dark;
      color: white;
      font-weight: bold;
    }
  }
}

.transparent-btn-primary {
  border-radius: 20px;
  color: $white;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  font-size: 75%;
  border: 1px solid rgb(209, 31, 122);
  border-radius: 1.25rem;
  opacity: 1;
}

// ng-select dropdown read-only fix
.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}

.ng-select.disable-dropdown ng-dropdown-panel {
  display: none;
}

.ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  background-color: $primary !important;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  border-color: $primary !important;
}

.toast-modal .toast-body {
  padding: 0.67rem 0.75rem 0.75rem 0.75rem;
}

.afterSignOffModal .modal-content {
  border: none !important;
  height: 500px !important;
}

// todo IN VARS
.modal-header {
  padding: 0.75rem 1.8125rem;
  align-items: center;
}
.modal-header button.close {
  margin: 0;
  padding: 0 0.3125rem;
  font-size: 0.875rem;
  color: var(--primary);
  position: relative;
  right: -0.3125rem;
  margin-left: auto;
}

// BOOTSTRAP STYLE ADDITIONS

.btn-primary {
  border: none;

  &:hover {
    background-color: lighten($primary, 10%);
    fill: lighten($primary, 10%);
  }
}

.icon-btn-primary {
  border: none;

  &:hover {
    filter: brightness(150%);
  }

  &:active {
    filter: brightness(75%);
  }
}

.btn-secondary {
  border: none;

  &:hover {
    background-color: lighten($secondary, 10%);
  }
}

.btn-success {
  border: none;

  &:hover {
    background-color: lighten($success, 10%);
  }
}

.btn-danger {
  border: none;

  &:hover {
    background-color: lighten($danger, 10%);
  }
}

.btn-warning {
  border: none;

  &:hover {
    background-color: lighten($warning, 10%);
  }
}

.btn-info {
  border: none;

  &:hover {
    background-color: lighten($info, 10%);
  }
}

.btn-dark {
  border: none;

  &:hover {
    background-color: lighten($dark, 10%);
  }
}

.btn-darkerGrey {
  border: none;
  background-color: #3e4055;
  &:hover {
    background-color: lighten(#3e4055, 10%);
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(62 64 85 / 50%);
    background-color: brightness(75%);
  }
}

.btn-outline-primary {
  color: $white;

  &:hover {
    background-color: rgba($primary, 0.1);
  }
}

.btn-outline-secondary {
  color: $white;

  &:hover {
    background-color: rgba($secondary, 0.3);
  }
}

.btn-outline-success {
  color: $white;

  &:hover {
    background-color: rgba($success, 0.1);
  }
}

.btn-outline-danger {
  color: $white;

  &:hover {
    background-color: rgba($danger, 0.1);
  }
}

.btn-outline-warning {
  color: $white;

  &:hover {
    color: $white;
    background-color: rgba($warning, 0.1);
  }
}

.btn-outline-info {
  color: $white;

  &:hover {
    background-color: rgba($info, 0.3);
  }
}

.btn-outline-light {
  color: $white;

  &:hover {
    color: $white;
    background-color: rgba($light, 0.1);
  }
}

.form-control:disabled {
  opacity: 0.375;
}

.form-control:focus {
  box-shadow: none !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary;
  border-radius: 50%;
}

.custom-checkbox:not(.custom-switch) .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary;
  border-radius: 4px;
}

.custom-switch .custom-control-label::before {
  background-color: $info;
  border-color: $info;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $info;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

.custom-switch .custom-control-label::after {
  background-color: $white;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $white;
}

.close {
  opacity: 1;
}

.card.bg-light .card-body {
  color: $secondary;
}

.card-header {
  background-color: transparent;
}

.list-group-item-action:not(.active) {
  opacity: 0.32;
}

custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.32;
}

// .ng-select .ng-select-container {
//   color: #333;
//   border-radius: 4px;
//   //border: 1px solid #ccc;
//   min-height: 40px !important;
//   align-items: center;
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
//   top: 5px;
//   padding-bottom: 5px;
//   padding-left: 6px;
//   padding-top: 8px;
// }

// .ng-placeholder {
//   font-size: 0.8em !important;
//   height: 40px;
//   border: 0px solid #999;
//   color: #fff !important;
//   padding-top: 6px;
//   background-color: #2b2940;
//   border-radius: 0.25rem;
//   font-weight: 300;
// }

/* CSS hacks for modals */
.modal-header {
  //display: block;
  text-transform: uppercase;
}
.modal-footer {
  padding: 0.75rem 1.8125rem;
}

.modal-body p {
  font-size: 0.875rem;
}

.modal-body ol {
  margin-bottom: 1rem;
}

.modal-body li {
  font-size: 14px;
  list-style: disc;
  margin-left: 1rem;
}

.modal-body ol li {
  list-style-type: auto;
}

.modal {
  &.show .modal-dialog {
    transition: 0.25s all;
    // transform: translate(0, -5vh);
  }
  &.custom-show .modal-dialog {
    opacity: 1;
    transform: translate(0, 0);
  }
  .modal-body {
    padding: 1.8125rem;
    &:focus {
      outline: none;
    }
  }
}
.xlScrollableModal.modal.show .modal-dialog {
  transform: none;
}

.termsText {
  max-width: 1050px;
}

.termsText p,
.termsText li {
  font-size: 0.6875rem;
}

.termsText h1,
.termsText h2,
.termsText h3 {
  font-size: 0.75rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.termsText.data h2 {
  font-size: 1rem;
}

// .modal-body .privacy-policy h2{
//   margin-top: 0;
//   margin-bottom: 0;
// }

.termsText h2:first-child {
  margin-top: 0;
}

.termsText ol,
.modal-body .termsText ul {
  margin-bottom: 1rem;
}

.termsText ul ul {
  margin-left: 1rem !important;
}

// .modal-body .privacy-policy li {
//   padding-left: 1rem;
// }

.termsText ol.alpha {
  list-style-type: lower-alpha;
}

.termsText ol.alpha > li {
  list-style-type: lower-alpha;
}

.termsText ol.roman li {
  list-style-type: lower-roman;
}

.viewDDQDiff {
  display: block;
  margin: 10px 0;
}

.termsText span {
  display: inline-block;
  position: absolute;
  left: 0;
  padding-left: 20px;
}
.termsText .wrapper {
  margin-left: 60px;
}

.termsText.data ol.alpha > li {
  list-style-type: lower-alpha;
  padding-left: 1rem;
  margin-bottom: 0.8rem;
}

.termsText table {
  border-collapse: collapse;
}

.termsText th,
.termsText td {
  border: 1px solid #fff;
  padding: 8px;
  font-size: 0.6875rem;
}

.DDQSubheadHr {
  border-color: red;
  margin-top: -20px;
}

ngb-typeahead-window .dropdown-item {
  padding: 8px 15px;
}
.progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 0;
  padding: 0;
  margin: 0;
  top: 0;
  content: 'Loading';
  .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50px;
    content: 'Loading';
    // -webkit-transform: translate(0, 0) scale(1, 1);
    // transform: translate(0, 0) scale(1, 1);
    // background-color: rgb(170, 209, 249);

    .bar {
      transition: all 0.2s linear;
      -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);

      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      // background-color: #7367f0;
      // background-color: rgb(16, 108, 200);
      content: 'Loading';
      position: absolute;
      // left: 0;
      // top: 0;
      // bottom: 0;
      width: 100%;
      height: 25px;
    }
  }
}
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/* SEARCH BAR */
.form-control.input.searchbar {
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  background-image: url(/assets/search-icon-pink.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.form-control.input.searchbar::placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

.dark.form-control.input.searchbar {
  background-color: $dark;
}

/* INPUT CLEAR 'X' */
.ng-select span.ng-clear {
  color: #d1e6f3;
  opacity: 0.32;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #d1e6f3;
  opacity: 0.5;
}

// datatable-scroller {
//   width: 100% !important;
// }

// .datatable-scroll {
//   width: 100% !important;
// }

.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.modal-content-dirty-request {
  top: 107px;
  position: fixed;
}

// upgrade fixes
datatable-header {
  height: 30px !important;
}

.printable .modal-body {
  background: #fff !important;
  color: #000 !important;
  background-color: #fff !important;
}

.printable .modal-body .form-control,
.printable .modal-body .ng-select > .ng-select-container,
.printable .modal-body .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
.printable .modal-body button.btn.btn-link.calendar {
  color: #000 !important;
  background-color: #fff;
  border: none;
}

.printable .modal-body textarea::placeholder {
  display: none;
}

.printable .modal-body .custom-control-input ~ .custom-control-label {
  opacity: 0.3 !important;
}

.printable .modal-body .form-control,
.printable .modal-body .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
.printable .modal-body .form-check-label {
  opacity: 0.7 !important;
}

.printable .modal-body .ng-select-container.ng-has-value {
  opacity: 0.7 !important;
}

.printable .modal-body .custom-control-input:checked ~ .custom-control-label {
  opacity: 0.7 !important;
}

.printable .modal-body form {
  position: relative;
}

.printable .modal-body form:after {
  content: '';
  position: absolute;
  width: 100%;
  background-color: #ff960b00;
  z-index: 99999999999;
  display: block;
  top: 0;
  inset: 0 0 0 0;
}
.printable .modal-body .commentButtonColumn {
  display: none !important;
}

.printable .modal-body label.fieldLabel.disabled-label {
  opacity: 1 !important;
}

.printable .modal-body .formField .formArray:after,
.printable .modal-body .form-row:after,
.printable .modal-body .formArray.disabled:after {
  display: none !important;
}

.printable .modal-body label.form-check-label.custom-control-label.disabled-label.readonly-cursor {
  color: inherit;
}

.printable .modal-body .groupAddRow button.btn.btn-outline-primary.btn-add,
.printable .modal-body .btn.btn-link.btn-remove,
.printable .modal-body .popOver {
  display: none;
}

.printable .modal-body .subHead {
  opacity: 0.7 !important;
  color: #000 !important;
}

// .printable .modal-body textarea[disabled] {
//   display: none;
// }

.printable .modal-body .input-group-prepend {
  display: none;
}

.printable .modal-body .ng-select-container {
  display: block !important;
}

@media print {
  body,
  html {
    overflow: scroll !important;
    color: #000 !important;
    background: #fff;
  }
  body * {
    visibility: hidden !important;
    height: fit-content;
    //display:block;
  }
  .printable .modal-dialog,
  .printDoc,
  .printDoc .modal-dialog,
  .printDoc *,
  .printable .modal-dialog *,
  .printDoc .modal-dialog * {
    visibility: visible !important;
  }
  .printable .modal-dialog,
  .printDoc .modal-dialog,
  .printDoc {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100% !important;
  }
  .printDoc,
  .printable .modal-dialog {
    height: unset !important;
    margin-bottom: 2000px;
  }
  .modal {
    position: unset !important;
  }

  .modal-header {
    display: none;
  }

  .printDoc .modal-header {
    display: block;
  }

  .modal-content {
    border: none;
  }
  .printable .modal-body form:after {
    display: none;
  }
  .termsText .wrapper {
    padding-bottom: 660px;
  }

  .printable .modal-body form:after {
    display: none;
  }

  .groupAddRow,
  .groupActionsRow,
  .add-comment-btn {
    display: none !important;
  }

  li {
    display: list-item;
  }
  strong,
  b,
  a {
    display: inline;
  }
  .snapShotAlert > * {
    display: inline-block !important;
  }

  .ng-value-label,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #000 !important;
    background: #fff;
  }

  .modal-dialog.modal-dialog-scrollable.modal-xl {
    max-height: unset !important;
  }

  .modal-content {
    max-height: unset !important;
    background-color: transparent !important;
  }

  .modal-dialog-scrollable .modal-content {
    overflow: visible !important;
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    height: unset !important;
  }

  .modal-footer {
    display: none;
  }

  // textarea[disabled] {
  //   display: none;
  // }

  table.report-container {
    page-break-after: always;
  }
  thead.report-header {
    display: table-header-group;
  }
  tfoot.report-footer {
    display: table-footer-group;
  }
  // table.report-container div.formField {
  //   page-break-inside: avoid;
  // }
  table.report-container .lastSection {
    page-break-inside: avoid;
    page-break-before: always;
  }
  .modal .modal-body {
    padding: 0;
  }

  .modal-dialog {
    margin: 0;
  }

  .printable .modal-dialog table {
    border: none;
  }

  *::-webkit-scrollbar-track {
    background: transparent !important;
  }
  * {
    scrollbar-width: 0;
    scrollbar-color: var(--trackThumb) transparent !important;
  }
  /* BOOTSTRAP 3 for PRINT */

  textarea {
    overflow: hidden;
  }

  .snapShotAlert img {
    display: inline;
  }

  .form-row {
    display: unset !important;
  }

  textarea.form-control {
    padding: 0;
  }

  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
  }
  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333333%;
    min-height: 26px;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-12 {
    width: 100%;
  }
  label.formNav {
    position: relative;
    top: 2px;
  }
}

/* BOOTSTRAP 5 Hacks */

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row {
  align-items: end;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-row.centered {
  align-items: center;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 992px) {
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.btn-link:focus,
.btn-link:active,
.btn-link:active:focus {
  box-shadow: none;
}

.btn-link {
  text-decoration: none;
}

/* BS4 checkbox */
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.125rem;
  padding-left: 1.75rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.checkboxContainer.custom-checkbox.custom-control {
  position: relative;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.0625rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

label {
  font-size: 0.688rem;
  margin-bottom: 0.375rem;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.0625rem;
  left: -1.75rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #d11f7a;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #d11f7a;
  box-shadow: none;
}

.custom-control-label:before {
  pointer-events: none;
  background-color: #1c1a32;
  border: 1px solid #4f5271;
}

/* radio */
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-control-label::after {
  position: absolute;
  top: 0.0625rem;
  left: -1.75rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: 50%/50% 50% no-repeat;
}

.badge-danger {
  background-color: $danger;
}

.badge-success {
  background-color: $success;
}

.badge-warning {
  background-color: $yellow;
}

.table > :not(caption) > * > * {
  color: #fff;
  background-color: transparent;
  padding: 0;
}

.table a,
.datatable-body-cell-label a,
a.datatable-icon,
a.datatable-icon-right {
  text-decoration: none;
}

.percentNumberContainer.input-group-append {
  display: flex;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

body .commentsMenu button.dropdown-item {
  color: #000;
}
body .commentsMenu button.dropdown-item:hover {
  color: #fff;
}
